var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"rules"},[_c('b-button',{staticClass:"ll-cancel",staticStyle:{"margin-top":"10px","margin-right":"5px"},attrs:{"variant":"flat-secondary","to":{ name: 'routing-service' }}},[_c('b-img',{staticClass:"mr-1",attrs:{"src":require('@/assets/images/imgs/return.png'),"height":"auto"}})],1),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t("Business Profile"))+" ")])],1),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Label Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Label Name')}},[_c('b-form-input',{attrs:{"id":"name","readonly":"","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Company Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Company Name')}},[_c('b-form-input',{attrs:{"readonly":"","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.displayName),callback:function ($$v) {_vm.$set(_vm.form_data, "displayName", $$v)},expression:"form_data.displayName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Company Type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('Company Type')}},[_c('v-select',{attrs:{"options":_vm.typeList,"label":"codeDescription","disabled":true,"state":errors[0] ? false : valid ? true : null},on:{"input":_vm.selType},model:{value:(_vm.form_data.orgType),callback:function ($$v) {_vm.$set(_vm.form_data, "orgType", $$v)},expression:"form_data.orgType"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.$t('KE FOX code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":_vm.$t('KE FOX code')}},[_c('b-form-input',{attrs:{"readonly":"","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.form_data.keAccountNumber),callback:function ($$v) {_vm.$set(_vm.form_data, "keAccountNumber", $$v)},expression:"form_data.keAccountNumber"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}])})],1)],1)],1)],1),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t("Routing Service")))])],1),_c('b-card-body',[_c('vxe-table',{ref:"xTable",attrs:{"border":"","show-overflow":"","keep-source":"","max-height":"400","data":_vm.items,"edit-rules":_vm.validRules,"edit-config":{trigger: 'click', mode: 'row', showStatus: true},"empty-text":"No matching records found"},on:{"edit-closed":_vm.editClosedEvent}},[_c('vxe-table-column',{attrs:{"field":"createdAt","width":"180","title":_vm.$t('Creation Time')}}),_c('vxe-table-column',{attrs:{"field":"routingService","title":_vm.$t('Routing Service')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('validation-provider',{attrs:{"name":"routingService","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"popper-class":"elselect","size":"small","placeholder":"please select"},model:{value:(row.routingService),callback:function ($$v) {_vm.$set(row, "routingService", $$v)},expression:"row.routingService"}},_vm._l((_vm.routingServiceList),function(group){return _c('el-option-group',{key:group.label,attrs:{"label":group.label}},_vm._l((group.options),function(item){return _c('el-option',{key:item.routingService,attrs:{"label":item.frontEndname,"value":item.routingService}})}),1)}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}])}),_c('vxe-table-column',{attrs:{"field":"priority","width":"200","title":_vm.$t('Priority')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('validation-provider',{attrs:{"name":"priority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(row.priority),callback:function ($$v) {_vm.$set(row, "priority", $$v)},expression:"row.priority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}])}),_c('vxe-table-column',{attrs:{"field":"config","title":_vm.$t('Configuration')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('validation-provider',{attrs:{"name":"config","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text"},model:{value:(row.config),callback:function ($$v) {_vm.$set(row, "config", $$v)},expression:"row.config"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}])}),_c('vxe-table-column',{attrs:{"field":"statusCode","title":_vm.$t('Status Trigger')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('validation-provider',{attrs:{"name":"statusCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{staticStyle:{"width":"100%"},attrs:{"size":"small","placeholder":"please select"},model:{value:(row.statusCode),callback:function ($$v) {_vm.$set(row, "statusCode", $$v)},expression:"row.statusCode"}},_vm._l((_vm.triggerList),function(item){return _c('el-option',{key:item,attrs:{"label":item,"value":item}})}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]}}])}),_c('vxe-table-column',{attrs:{"title":_vm.$t('Action'),"width":"100","show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-button',{attrs:{"status":"danger"},on:{"click":function($event){return _vm.removeEvent(row)}}},[_vm._v("Delete ")])]}}])})],1),_c('vxe-button',{staticStyle:{"margin-top":"10px"},attrs:{"status":"primary"},on:{"click":function($event){return _vm.insertEvent()}}},[_vm._v(_vm._s(_vm.$t('Add')))]),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"info"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("Update")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }