<template>
  <validation-observer ref="rules">
     <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'routing-service' }"
      style="margin-top:10px;margin-right:5px"
    >
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t("Business Profile") }}
         </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="3">
            <validation-provider :name="$t('Label Name')" rules="required">
              <b-form-group :label="$t('Label Name')" slot-scope="{ valid, errors }">
                <b-form-input id="name" v-model="form_data.name" readonly :state="errors[0] ? false : valid ? true : null" />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Company Name')" rules="required">
              <b-form-group :label="$t('Company Name')" slot-scope="{ valid, errors }">
                <b-form-input v-model="form_data.displayName" readonly :state="errors[0] ? false : valid ? true : null" />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider :name="$t('Company Type')" rules="required">
              <b-form-group :label="$t('Company Type')" slot-scope="{ valid, errors }">
                <v-select
                  :options="typeList"
                  label="codeDescription"
                  @input="selType"
                  v-model="form_data.orgType" :disabled="true" :state="errors[0] ? false : valid ? true : null"
                >
                </v-select>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
         <b-col md="3">
            <validation-provider :name="$t('KE FOX code')" rules="required">
              <b-form-group :label="$t('KE FOX code')" slot-scope="{ valid, errors }">
                 <b-form-input v-model="form_data.keAccountNumber" readonly :state="errors[0] ? false : valid ? true : null" />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title>{{ $t("Routing Service") }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <vxe-table
            border
            show-overflow
            keep-source
            ref="xTable"
            max-height="400"
            :data="items"
            :edit-rules="validRules"
            @edit-closed="editClosedEvent"
            :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
            empty-text="No matching records found"
            >
                <vxe-table-column field="createdAt" width="180" :title="$t('Creation Time')"  >
                </vxe-table-column>
                <vxe-table-column field="routingService" :title="$t('Routing Service')"  >
                    <template #default="{ row }">
                        <validation-provider
                        #default="{ errors }"
                        name="routingService"
                        rules="required"
                        >
                        <!-- <b-form-select :options="routingServiceList" style="background:#fff"
                        v-model="row.routingService" /> -->
                        <el-select v-model="row.routingService" popper-class="elselect" size="small" placeholder="please select" style="width:100%">
                            <el-option-group
                            v-for="group in routingServiceList"
                            :key="group.label"
                            :label="group.label">
                            <el-option
                                v-for="item in group.options"
                                :key="item.routingService"
                                :label="item.frontEndname"
                                :value="item.routingService">
                            </el-option>
                            </el-option-group>
                        </el-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </template>
                </vxe-table-column>
                
                <vxe-table-column field="priority" width="200" :title="$t('Priority')"
                >
                    <template #default="{ row }">
                    <validation-provider
                        #default="{ errors }"
                        name="priority"
                        rules="required"
                    >
                        <b-form-input
                        v-model="row.priority"
                        type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </template>
                </vxe-table-column>
                <vxe-table-column
                    field="config" :title="$t('Configuration')">
                    <template #default="{ row }">
                    <validation-provider
                        #default="{ errors }"
                        name="config"
                        rules=""
                    >
                        <b-form-input
                        v-model="row.config"
                        type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </template>
                </vxe-table-column>
                <vxe-table-column
                    field="statusCode" :title="$t('Status Trigger')">
                    <template #default="{ row }">
                    <validation-provider
                        #default="{ errors }"
                        name="statusCode"
                        rules="required"
                    >
                     <el-select v-model="row.statusCode" size="small" placeholder="please select" style="width:100%">
                        <el-option
                        v-for="item in triggerList"
                        :key="item"
                        :label="item"
                        :value="item">
                        </el-option>
                    </el-select>
                        <!-- <b-form-select :options="triggerList" v-model="row.statusCode" /> -->
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="$t('Action')" width="100" show-overflow>
                <template #default="{ row }" >
                    <vxe-button status="danger" @click="removeEvent(row)">
                    <!-- <feather-icon icon="Trash2Icon" /> -->Delete
                    </vxe-button>
                </template>
                </vxe-table-column>
            </vxe-table>
            <vxe-button status="primary" @click="insertEvent()" style="margin-top:10px">{{ $t('Add') }}</vxe-button>
            <div class="text-right">
                <b-button variant="info" @click="save">{{ $t("Update")}}</b-button>
            </div>
      </b-card-body>
    </b-card>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard, BCardBody, BButton,BRow, BCol, BFormGroup, BFormInput,BCardHeader,
  BCardTitle,BFormInvalidFeedback
} from 'bootstrap-vue'
export default {
  components: {
    vSelect,
    ToastificationContent,
    BRow, BCol,
    BCard, BCardBody,
    // ValidationProvider,
    // ValidationObserver,
    BButton,
     BFormGroup, BFormInput,
     BCardHeader,BCardTitle,
     BFormInvalidFeedback,
  },
  data() {
    return {
      typeList: [],
      items:[],
      form_data: {},
      validRules: {},
      routingServiceList:[
        // {
        //     label: 'Label',
        //     options: [
        //       { value: 'LABEL_WITH_SORT_CODE_SERVICE', text: 'LMD standard label with KE logo' },
        //       { value: 'LABEL_WITH_HIDE_PHONE_SERVICE', text: 'LMD label hide consignee phone number' },
        //       { value: 'LABEL_WITH_SORT_CODE_LOGO_SERVICE', text: 'LMD label with KEC logo' },
        //       { value: 'MERCHANT_LABEL_SERVICE', text: 'LMD marchant label' },
        //       { value: 'LABEL_COSOL_ROUTING_SERVICE', text: 'KE CONSOL use' },
        //       { value: 'LABEL_COSOLTWO_ROUTING_SERVICE', text: 'FOMS use' },
        //       { value: 'LABEL_CUS_ROUTING_SERVICE', text: 'CUS standard label' },
        //     ]
        //   },
        //   {
        //     label: 'Auto Push Order to 3rd Party',
        //     options: [
        //       { value: 'THK_ROUTING_SERVICE', text: 'Order to TEHK' },
        //       { value: 'EPOD_ROUTING_SERVICE', text: 'Order to EPOD' },
        //       { value: 'ZEEK_ROUTING_SERVICE', text: 'Order to ZEEK' },
        //     ]
        //   },
        //    {
        //     label: 'Internal System Service',
        //     options: [
        //       { value: 'RSS_ROUTING_SERVICE', text: 'RSS sort code service' },
        //       { value: 'PSS_ROUTING_SERVICE', text: 'PSS warehouse service' },
        //       { value: 'KES_ROUTING_SERVICE', text: 'KES EDI service' },
        //       { value: 'KES_STATUSIN_ROUTINGSERVICE', text: 'KES status service' },
        //       { value: 'KEHK_ROUTING_SERVICE', text: 'KE FOX EDI service' },
        //       { value: 'KEHK_01_ROUTING_SERVICE', text: 'AMZUS EDI to KE FOX service' },
        //     ]
        //   },
        //   {
        //     label: 'SMS Service',
        //     options: [
        //       { value: 'SMS_ROUTING_SERVICE', text: 'Send SMS to consignee' },
        //       { value: 'ZEEK_MMS_SERVICE', text: 'NK Project ZEEK MMS Service' },
        //     ]
        //   },
      ],
      triggerList:[
        'ORDER_RECEIVED',
        'FST_WAREHOUSE_INBOUND',
        'WAREHOUSE_OUTBOUND',
        'OUT_FOR_DELIVERY',
        'DELIVERED',
        'PICK_PACK_COMPLETED',
        'PREALEART_SUCCESS',
      ]
    };
  },
  mounted() {
    this.queryOrgType()
    this.queryRouting()
    this.getDetails()
    if(this.$route.params.id && Number(this.$route.params.id) != 0){
      this.getOrgCode()
    }
  },
  methods: {
      save(){
        const $table = this.$refs.xTable
        if($table.tableData.some(i => i.routingService == null || i.routingService == ''  )){
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Routing Service can not be empty',
                icon: 'XIcon',
                variant: 'danger',
            },
            })
            return
        }
        if($table.tableData.some(i => i.statusCode == null || i.statusCode == '')){
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Status Trigger can not be empty',
                icon: 'XIcon',
                variant: 'danger',
            },
            })
            return
        }
        if($table.tableData.some(i => i.priority == null || i.priority == '')){
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Priority can not be empty',
                icon: 'XIcon',
                variant: 'danger',
            },
            })
            return
        }
        if ($table !== undefined) {
            const arr = []
            $table.tableData.map(i =>{
            const obj = {}
            obj.routingService = i.routingService
            obj.priority = i.priority
            obj.config = i.config
            obj.statusCode = i.statusCode
            obj.orgID = this.$route.params.code
            obj.id = i.id?i.id:null
            arr.push(obj)
            })
            this.routingRule = arr
        } else {
            this.routingRule = []
        }
        this.$refs.rules.validate().then(success => {
            if (success) {
                 this.$http.post('/routeService/save', {routingRule:this.routingRule}).then(res => {
                    if (res.data.code === '200') {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: 'Update Success',
                        icon: 'CheckIcon',
                        variant: 'success',
                        },
                    })
                    this.$router.push({ name: 'routing-service' })
                    } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: res.data.message,
                        icon: 'XIcon',
                        variant: 'danger',
                        },
                    })
                    }
                })
            }
         
        })
        
      },
      getOrgCode() {
        this.$http.post('admin/settings/organization/getInfoById', {id:this.$route.params.id} ).then(res => {
          const item = res.data.data
          this.form_data = item
        })
      },
      getDetails(){
         this.$http.get('/routeService/findByOrgID?orgID='+this.$route.params.code).then(res => {
          const item = res.data.data
          this.items = res.data.data
        })
      },
      queryOrgType() {
        this.$http.get('/masterData/findAllByDataType', { params: { data_type: 'orgType'}}).then(res => {
          this.typeList = res.data.data
        })
      },
       queryRouting() {
        this.$http.get('/routeService/routingrulegroup').then(res => {
          this.routingServiceList = res.data.data
        })
      },
      selType(d) {
        if (d) {
          this.form_data.orgType = d.codeName
          this.form_data.type = d.codeDescription
        } else {
          this.form_data.orgType = null
          this.form_data.type = null
        }
      },
       // 插入新一行
    async insertEvent (row) {
        this.isShow = true
        const $table = this.$refs.xTable
        const record = {}
        const { row: newRow } = await $table.insertAt(record, row)
        await $table.setActiveCell(newRow)
    },
      removeEvent (row) {
            this.box = ''
            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete .', {
                title: 'Handle',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {
                if (value) {
                this.box = value
                if (row.id !== undefined){
                    this.$http.get(`/routeService/del?id=${row.id}`).then(res=>{
                    if (res.data.code === '200') {
                        this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Delete Success',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                        })
                    } else {
                        this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: res.data.message,
                            icon: 'XIcon',
                            variant: 'danger',
                        },
                        })
                    }
                    setTimeout(() => {
                        this.getDetails()
                    }, 1000);
                    })
                }
                this.$refs.xTable.remove(row)
                }
            })
        },
    editClosedEvent ({ row, column }) {
        const $table = this.$refs.xTable
        const field = column.property
        const cellValue = row[field]
    },
  }
};
</script>

<style >
.elselect .el-select-dropdown__wrap {
    /* background-color: bisque !important; */
    max-height: 350px !important;
}
[dir] .sp-st .vs__selected{
  background-color: #ed6d01 !important;
  border: 0 solid rgba(60, 60, 60, 0.26) !important;
  border-radius: 0.357rem !important;
  margin: 4px 2px 0px 2px !important;
  padding: 0 0.25em !important;
}

 .sp-st svg.feather{
   display: none !important;
 }
.check-box .el-checkbox__inner{
  width: 18px;
  height: 18px;
  /* background-size: 100%; */
}
/* [dir] .check-box .el-checkbox__inner::after{
  width: 18px;
  height: 18px;
} */
[dir] .check-box .el-checkbox__input.is-checked .el-checkbox__inner, [dir] .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #ed6d01;
    border-color: #ed6d01;
}
.check-box .el-checkbox__inner::after {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    content: "";
    height: 8px;
    position: absolute;
    top: 2px;
    width: 4px;
}

</style>
